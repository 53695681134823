import request from "@/common/utils/request.js";
import { WebClient } from "@/api/webClient";
import data from "area-data";
let webClient = new WebClient();

export class CouponsClient extends WebClient {
  /* --------------优惠券 ------------------------------------------------------ */
  /**查看可用优惠券 */
  async useDiscountCouponCode(least, type, convertorId, typeId) {
    let data = await request({
      url: `/market/discountCoupon/useDiscountCouponCode`,
      method: "GET",
      params: {
        least: least,
        type: type,
        convertorId: convertorId,
        typeId: typeId,
      },
    });
    return data;
  }
  /**pc端兑换券 兑换 */
  async studentExchange(code, couponCode) {
    let data = await request({
      url: `/market/exchangeCoupon/studentExchange`,
      method: "POST",
      params: {
        code: code,
        couponCode: couponCode,
        source: "PC端",
      },
    });
    // this.functionThen(data)
    return data;
  }
  /**  pc端领取优惠券 */
  async getUserIdCouponCode(couponId) {
    let data = await request({
      url: `/market/discountCoupon/getUserIdCouponCode`,
      method: "POST",
      params: {
        couponId: couponId,
      },
    });
    this.functionThen(data);
    return data;
  }
  /**pc端优惠券列表
   *  couponType 1：无门槛券 2：满减券',
   */
  async getDiscountPcList(pageSize, pageNum, userid, couponType) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
        userId: userid,
      },
      {
        couponType: couponType,
      }
    );
    let data = await request({
      url: `/market/discountCoupon/getDiscountPcList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**pc获取学生当前优惠券
   *  couponType 1：无门槛券 2：满减券',
   *useStatus 是否使用 （0：未使用 1：已使用）
   */
  async getDiscountCouponCodeListByConvertorId(
    pageSize,
    pageNum,
    couponType,
    useStatus
  ) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        couponType: couponType,
        useStatus: useStatus,
      }
    );
    let data = await request({
      url: `/market/discountCoupon/selectUserCouponCode`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /*ya  */
  // 图形验证码
  async imagecode() {
    let data = await request({
      url: "/stu/image/code",
      method: "GET",
      responseType: "arraybuffer",
    });
    return data;
  }
  // 注册验证图形验证码
  async selectCode(code) {
    let data = await request({
      url: "/stu/front/selectCode",
      method: "GET",
      params: {
        code: code,
      },
    });
    return data;
  }
  /* -------------------兑换券-------------------- */
  /**pc端兑换券 兑换 */
  async studentExchange(code, couponCode) {
    let data = await request({
      url: `/market/exchangeCoupon/studentExchange`,
      method: "POST",
      params: {
        code: code,
        couponCode: couponCode,
        source: "PC端",
      },
    });
    // this.functionThen(data)
    return data;
  }
  /* -----------------------支付---------------------- */
  // 查询微信支付订单支付状态
  async queryOrder(code) {
    let data = await request({
      url: "/stu/wxPay/queryOrder",
      method: "GET",
      params: {
        outTradeNo: code,
      },
    });
    return data;
  }

  // 用户注册发送短信
  async loginSmsCode(tel, smsTmplName) {
    let data = await request({
      url: "/stu/account/loginSmsCode",
      method: "GET",
      params: {
        tel: tel,
        smsTmplName: smsTmplName,
      },
    });
    return data;
  }
  // 个人中心 账号绑定 更换手机号
  async pcUserBindMobile(mobile, sjToken) {
    let data = await request({
      url: "/stu/Personal/pcUserBindMobile",
      method: "POST",
      params: {
        mobile: mobile,
        sjToken: sjToken,
      },
    });
    return data;
  }
  //pc端优惠券列表
  async getDiscountPcList(pageSize, pageNum, userId, couponType) {
    let data = await request({
      url: "/market/discountCoupon/getDiscountPcList",
      method: "GET",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
        userId: userId,
        couponType: couponType,
      },
    });
    return data;
  }
  //pc端领取优惠券
  async getUserIdCouponCode(couponId) {
    let data = await request({
      url: "/market/discountCoupon/getUserIdCouponCode",
      method: "POST",
      params: {
        couponId: couponId,
      },
    });
    return data;
  }

  //学生添加反馈消息
  async saveFeedback(title, content, type, img, userId) {
    let data = await request({
      url: "/stu/edu/feedback/saveFeedback",
      method: "POST",
      data: {
        title: title,
        content: content,
        type: type,
        img: img,
        userId: userId,
      },
    });
    return data;
  }
  /**选择学习卡支付 前验证 */
  async VerificationRechargeCard(item) {
    let data = await request({
      url: `/ucenter/recharge/VerificationRechargeCard`,
      method: "POST",
      data: item,
    });
    this.functionShow(data);

    return data;
  }
  /**选择学习卡支付 */
  async rechargeCardPay(item) {
    let data = await request({
      url: `/stu/wxPay/rechargeCardPay`,
      method: "POST",
      params: item,
    });
    if (data.msg == 501) {
      // this.functionThen(data)
      this.$notify.error({
        title: "错误",
        message: data.code,
      });
    }

    return data;
  }
}
